import React, { useEffect, useState, useRef } from 'react';
import NavBar from '../Components/NavBar';
import '../css/Products.css';
import axios from 'axios';
import LoadingIcons from 'react-loading-icons';
import ProductComponent from '../Components/Product_Component';
import Categories from '../Components/Categories';
import Footer from '../Components/Footer';
import { useNavigate, useParams } from 'react-router-dom';


const Products = () => {
  const API = process.env.REACT_APP_BACKEND;
  const productsRef = useRef(null);
  
  const [data, setData] = useState([]);
  
  const [search, setSearch] = useState('');
  const { category, subcategory } = useParams();
  const [detail,setDetail]=useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleSearch = (searchValue) => {
    setSearch(searchValue);
};
  

const revertFormattedName = (name) => {
  if (!name) return '';

  try {    
    const decoded = decodeURIComponent(name);
    let result = decoded
      .split('_')
      .map(word => {
        // Common Hungarian word patterns
        word = word
          .replace(/fogaszat/g, 'fogászat')
          .replace(/rontgen/g, 'röntgen')
          .replace(/berendezes/g, 'berendezés')
          .replace(/kezel/g, 'kezel')
          .replace(/szek/g, 'szék')
          .replace(/feher/g, 'fehér')
          .replace(/keszulek/g, 'készülék')
          .replace(/kezidarabok/g, 'kézidarabok')
          .replace(/elszivok/g, 'elszívók')
          .replace(/operacios/g, 'operációs')
          .replace(/lampak/g, 'lámpák')
          .replace(/muszerkocsik/g, 'műszerkocsik')
          .replace(/apolas/g, 'ápolás')
          .replace(/karbantartas/g, 'karbantartás')
          .replace(/sebeszeti/g, 'sebészeti')
          .replace(/kezeloegysegek/g, 'kezelőegységek')
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');

    return result;
  } catch (e) {
    console.error('Error decoding:', e);
    return name;
  }
};


  useEffect(() => {
    if (productsRef.current) {
      productsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${API}/termekek`, {
          params: {
            ...(search && { search }),
            ...(category && { category: revertFormattedName(category) }),
            ...(subcategory && { subcategory: revertFormattedName(subcategory) }),
            page: currentPage,
            limit: 30,
          },
        });
        setData(response.data.products);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error(error);
      }
    };
    fetchProducts();
    const getDetail = async (categoryName) => {
      try {
        const response=await axios.get(`${API}/detail`,{
          params: { category: revertFormattedName(categoryName) },
        })
        if (response.data.length > 0) {
          setDetail(response.data[0].detail); 
        } else {
          setDetail('No details found for this category.');
        }
      } catch (error) {
        console.log(error)
      }
    }
    getDetail(category)
    window.scroll(0,0)
  }, [category, subcategory, search, currentPage]);


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className='products'>
      <NavBar />
      <div className="products-main">
        
        <div className="products-category">
          <Categories onSearchChange={handleSearch} category={category} subcategory={subcategory} setCurrentPage={setCurrentPage}/>
        </div>

        {/* Products Section */}
        <div className='products-section'>
        <h1>{revertFormattedName(category)}</h1>
        <div className="products-items" ref={productsRef} id="products">
          {data.length > 0 ? (
            data.map((item, index) => (
              <ProductComponent key={index} item={item} />
            ))
          ) : (
            <div><LoadingIcons.Bars/></div>
          )}
          
        </div>
            <div className="pagination">
                <button 
                  onClick={() => handlePageChange(currentPage - 1)} 
                  disabled={currentPage === 1}
                  className="pagination-arrow"
                >
                  &larr; 
                </button>
                
                {Array.from({ length: totalPages }).map((_, index) => (
                  <button 
                    key={index} 
                    onClick={() => handlePageChange(index + 1)}
                    className={`pagination-dot ${index + 1 === currentPage ? 'active' : ''}`}
                  >
                    ● 
                  </button>
                ))}

                <button 
                  onClick={() => handlePageChange(currentPage + 1)} 
                  disabled={currentPage === totalPages}
                  className="pagination-arrow"
                >
                  &rarr;
                </button>
                {detail && (
              <p>{detail}</p>
              )}
              </div>
              
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default Products;