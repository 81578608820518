import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate} from 'react-router';
import '../css/Item.css';
import LoadingIcons from 'react-loading-icons';
import ReactPlayer from "react-player";
import SubProductsCard from '../Components/SubProductsCard';


const Item = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = location.state || {};
    const [product, setProduct] = useState(null);
    const [subProducts,setSubProducts] = useState(null);


    useEffect(() => {
        const API = process.env.REACT_APP_BACKEND;
        
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${API}/products/${id}`);
                setProduct(response.data);
            } catch (error) {
                console.error(error);
            }
        };
    
        fetchProduct();
    },[id]);

    return (
        <div>
            <NavBar />
            {product ? (
                <div className='item'>
                    <div className='item-header'>
                        <img src={product.image} alt={product.name} />
                        <div>
                            <h1>{product.name}</h1>
                            <p>{product.brand}</p>
                            <p>{product.category}</p>
                            <p>{product.subcategory}</p>
                            <button
                                onClick={() =>
                                    navigate(`/kapcsolat?offertag=${encodeURIComponent(product.name)} - Ajánlatkérés`)
                                }
                            >
                                Ajánlatkérés
                            </button>
                        </div>
                    </div>
                    <div className='item-description'>
                        <p dangerouslySetInnerHTML={{ __html: product.description }}></p>
                        {product.video && (
                            <ReactPlayer
                            url={product.video}
                            controls
                            width="100%"
                            height="360px"
                          />
                        )}
                    </div>
                </div>
            ) : (
                <div className="loading-container">
                    <LoadingIcons.Bars stroke="#3256a8"/>
                </div>
            )}
            <Footer />
        </div>
    );
};
export default Item;