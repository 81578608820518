import React from 'react'
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from 'react-icons/md';

const Contact_Cards = () => {
  return (
    <div className='contact-cards'>
        <h1>
            Munkatársaink
        </h1>
        <div className='contact-cards-rows'>
            <div className='contact-card'>
                <h2>Értékesítés/Szerviz</h2>
                <h3>Kenedli Flórián</h3>
                <div>
                <MdEmail />
                <p>info@dentalcoopplus.hu</p>
                </div>
                <div>
                <FaPhoneAlt />
                <p>+36 20 956 5480</p>
                </div>
            </div>
            <div className='contact-card'>
                    <h2>Pénzügy/Számlázás</h2>
                    <h3>Gergácz Viktória</h3>
                    <div>
                    <MdEmail />
                    <p>g.viktoria@dentalcoopplus.hu</p>
                    </div>
                    <div>
                    <FaPhoneAlt />
                    <p>+36 30 930 3588</p>
                    </div>
                </div>
        </div>
        <div className='contact-cards-rows'>
            <div className='contact-card'>
                    <h2>Ügyvitel/Marketing</h2>
                    <h3>Kovács Fanni</h3>
                    <div>   
                    <MdEmail />
                    <p>k.fanni@dentalcoopplus.hu</p>
                    </div>
                    <div>
                    <FaPhoneAlt />
                    <p>+36 20 251 1002</p>
                    </div>
            </div>
            <div className='contact-card'>
                    <h2>Irodavezető</h2>
                    <h3>Jámbor Vanessa</h3>
                    <div>
                    <MdEmail />
                    <p>j.vanessa@dentalcoopplus.hu</p>
                    </div>
                    <div>
                    <FaPhoneAlt />
                    <p>+36 30 227 3555</p>
                    </div>
            </div>
        </div>
        <div className='contact-cards-rows'>
            <div className='contact-card'>
                    <h2>Szerviz Technikus</h2>
                    <h3>Bencsics László</h3>
                    <div>
                    <MdEmail />
                    <p>info@dentalcoopplus.hu</p>
                    </div>
                    <div>
                    <FaPhoneAlt />
                    <p>+36 30 797 2941</p>
                    </div>
            </div>
            <div className='contact-card'>
                    <h2>Szerviz Technikus</h2>
                    <h3>Schwott András</h3>
                    <div>
                    <MdEmail />
                    <p>info@dentalcoopplus.hu</p>
                    </div>
                    <div>
                    <FaPhoneAlt />
                    <p>+36 20 955 7552</p>
                    </div>
            </div>
        </div>
        <div className='contact-cards-rows'>
            <div className='contact-card'>
                    <h2>Szerviz Technikus</h2>
                    <h3>Kemény Szabolcs</h3>
                    <div>
                    <MdEmail />
                    <p>info@dentalcoopplus.hu</p>
                    </div>
                    <div>
                    <FaPhoneAlt />
                    <p>+36 70 423 4350</p>
                    </div>
            </div>
            <div className='contact-card'>
                    <h2>Informatikus</h2>
                    <h3>Jámbor Marcell</h3>
                    <div>
                    <MdEmail />
                    <p>j.marcell@dentalcoopplus.hu</p>
                    </div>
                    <div>
                    <FaPhoneAlt />
                    <p>+36 20 564 3144</p>
                    </div>
            </div>
        </div>
    </div>
  )
}

export default Contact_Cards