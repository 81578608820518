import React from 'react';
import { clientsData } from '../data/clients';
import '../css/clients.css';

const Clients = () => {
  return (
      <div className="content-wrapper">
        <h1 className="section-title">ELÉGEDETT ÜGYFELEINK</h1>
        <div className="clients-container">
          {clientsData.map((item, i) => (
            <a key={i} href={item.host} target="_blank" rel="noopener noreferrer" className="client-link">
                <img className="client-image" src={item.image} alt={item.name} />
                <h6 className="client-name">{item.name}</h6>
            </a>
          ))}
        </div>
      </div>
  );
};

export default Clients;